@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family: 'Poppins';
  src: url('/public/fonts/Poppins-Regular.ttf') format("truetype");
  font-wight:400;
  font-display: swap;
}
html{
  scroll-behavior: smooth;
}
body{
  font-family:'Poppins'
}



/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
 .dot-typing {
  position: relative;
  left: -9999px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color:#ff2a2a;
  color:#ff2a2a;
  box-shadow: 9984px 0 0 0#ff2a2a, 9999px 0 0 0#ff2a2a, 10014px 0 0 0#ff2a2a;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0#ff2a2a, 9999px 0 0 0#ff2a2a, 10014px 0 0 0#ff2a2a;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0#ff2a2a, 9999px 0 0 0#ff2a2a, 10014px 0 0 0#ff2a2a;
  }
  33.333% {
    box-shadow: 9984px 0 0 0#ff2a2a, 9999px 0 0 0#ff2a2a, 10014px 0 0 0#ff2a2a;
  }
  50% {
    box-shadow: 9984px 0 0 0#ff2a2a, 9999px -10px 0 0#ff2a2a, 10014px 0 0 0#ff2a2a;
  }
  66.667% {
    box-shadow: 9984px 0 0 0#ff2a2a, 9999px 0 0 0#ff2a2a, 10014px 0 0 0#ff2a2a;
  }
  83.333% {
    box-shadow: 9984px 0 0 0#ff2a2a, 9999px 0 0 0#ff2a2a, 10014px -10px 0 0#ff2a2a;
  }
  100% {
    box-shadow: 9984px 0 0 0#ff2a2a, 9999px 0 0 0#ff2a2a, 10014px 0 0 0#ff2a2a;
  }
}